<template>
<v-container>
    <v-row>
        <v-col cols="12" md="6">
         Logging you out...
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
import {mapState} from "vuex";
  export default {
    name: 'Logout',
    mounted() {
       this.logout()
    },
    data: () => ({
        //
    }),
    computed : {
        ...mapState({
            authToken : state => state.authToken
        })
    },
    methods: {
        logout () {
            axios.post(process.env.VUE_APP_AUTHURL+'/signout',null,
            {
                headers: { 'Authorization': 'Bearer '+this.authToken }
            })
            .then(() => {                
                this.$store.commit("authToken", null);
                sessionStorage.removeItem('authToken')
            })
            .catch((error) => {
                console.log(error)
            })
            .finally(() => {
                this.$router.push('/')
            });
        },
    },
  }
</script>
